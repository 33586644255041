.bannerbg {
    background-image: url('../Assest/construction4.webp');
    background-position: center;
    background-size: cover;
}

.openupani {
    animation: openup linear 0.6s;
}

@keyframes openup {
    0% {
        height: 0px;
    }

    100% {
        min-height: 60vh;
    }
}

.outlined-text {
    color: transparent;
    /* Makes the fill transparent */
    -webkit-text-stroke: 1px black;
    /* Creates the outline */
}

.fixedimg {
    background-image: url('../Assest/constructionBlue.webp');
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
}
.scrolling::-webkit-scrollbar {
    width: 12px;
  }
  
.scrolling::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 6px;
  }
  
  /* Add a pseudo-element for the scrollbar corner */
  .scrolling::-webkit-scrollbar-corner {
    background-color: #f1f1f1;
  }