.jakarta {
  font-family: "Plus Jakarta Sans", sans-serif;
}

.saira {
  font-family: "Saira", sans-serif;
}
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

.poppins {
  font-family: "Poppins", sans-serif;
}

.sans {
  font-family: "DM Sans", sans-serif;
}

.bgclr{
  background-color: rgb(20,83,155);
}
.txtclr{
  color: rgb(20,83,155);
}
.brclr{
  border-color: rgb(20,83,155);
}

